const cardContents = [
  {
    title: "Privacy by design",
    paragraphs: [
      <p>
        Dbox uses a unique user ID for every website to connect to your inbox.
        The user ID contains no personal information, which means that it{" "}
        <b>cannot be traced</b> across the web. In case of data breaches,
        nothing of value is exposed as opposed to if an email address leaks.
      </p>,
      <p>
        This, combined with <b>complete operation on-chain</b> including data
        storage, results in that privacy becomes an essential component of the
        core functionality of dbox.
      </p>,
    ],
  },
  {
    title: "Interoperable",
    paragraphs: [
      <p>
        Dbox is built such that it is interoperable across different ecosystems.
        This way, dbox enables scalable and user-friendly communication across
        all blockchains. Whether it is for DeFi, NFTs, Web3, or other DApps,
        dbox ensures capable communication.
      </p>,
      <p>
        Thanks to this design, dbox is even accessible for the traditional web -
        making <b>global adoption</b> achievable.
      </p>,
    ],
  },
  {
    title: "Trustworthy",
    paragraphs: [
      <p>
        Dbox was awarded a grant from <b>DFINITY Foundation</b> in the first
        round of their Developer Grant Program.
      </p>,
      <p>
        We are proud to be selected as one of the 60 first entrepreneur teams to
        catalyze the growth of the Internet Computer ecosystem.
      </p>,
    ],
  },
];

export const Cards = () => {
  return (
    <div className="mx-auto py-24 px-10 gap-10 flex flex-wrap items-center justify-center bg-black">
      {cardContents.map((content) => (
        <div
          className="p-10 w-96 md:min-h-[29rem] flex flex-col gap-3 rounded-xl shadow bg-white"
          key={content.title}
        >
          <h2 className="mb-4 text-3xl font-bold">{content.title}</h2>
          {content.paragraphs.map((par) => (
            <div key={par.props.children}>{par}</div>
          ))}
        </div>
      ))}
    </div>
  );
};
