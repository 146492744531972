import { Nav } from "./Nav";
import { Newsletter } from "./Newsletter";
import { GradientSwirls } from "./GradientAnimation";
import { TryAlpha } from "./TryAlpha";
import { SellingPoints } from "./SellingPoints";
import { Cards } from "./Cards";
import {
  PrimaryButton,
  SecondaryButtonBlack,
  SecondaryButtonSVG,
} from "./Button";
import { useRef } from "react";
import { BackedBy } from "./BackedBy";

export const Content = () => {
  const readMoreRef = useRef();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const handleReadMoreClick = () => {
    readMoreRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main className="w-full">
      <div className="h-screen flex flex-col justify-between">
        <Nav />
        <div className="h-full">
          <div className="h-full flex items-center">
            <div className="z-10 w-full h-full md:ml-[15%] flex flex-col justify-evenly md:gap-16 md:justify-center items-center md:items-start">
              <h1 className="w-min md:w-auto text-[7vh] md:text-[8vh] lg:text-[9vh] leading-none max-w-md lg:max-w-3xl font-bold">
                <span className="inline-block mb-5 text-primary">
                  The new web is coming.
                </span>
                <span className="inline-block">
                  The dApp inbox is here.
                  {/* The new dApp mail standard is here. */}
                </span>
              </h1>
              <div className="flex flex-col md:flex-row gap-5 lg:gap-10">
                <a
                  href="https://2prn7-tqaaa-aaaai-acjja-cai.ic0.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton text="Try alpha" />
                </a>
                {isSafari ? (
                  <SecondaryButtonBlack
                    text="Read more"
                    clickHandler={handleReadMoreClick}
                  />
                ) : (
                  <SecondaryButtonSVG
                    text="Read more"
                    color="black"
                    clickHandler={handleReadMoreClick}
                  />
                )}
              </div>
            </div>
          </div>
          <GradientSwirls />
        </div>
        <div className="z-10 hidden lg:block">
          <Newsletter />
        </div>
      </div>
      <div className="lg:hidden">
        <Newsletter />
      </div>

      <div ref={readMoreRef}>
        <BackedBy />
      </div>

      <SellingPoints />

      <Cards />

      <TryAlpha />
    </main>
  );
};
