export const GradientBlobs = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="w-full h-full relative overflow-hidden">
        {/* <div className="absolute inset-x-[-20rem] inset-y-[-20rem] w-[40rem] h-[40rem] bg-gradient-main animate-gradient-x rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div> */}
        {/* <div className="absolute inset-x-[-20rem] inset-y-[20rem] w-[40rem] h-[40rem] bg-gradient-main animate-gradient-x rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div> */}
        <div className="absolute right-32 -top-32 w-[40rem] h-[40rem] bg-gradient-main animate-gradient-x rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob"></div>
        <div className="absolute -right-64 top-32 w-[40rem] h-[40rem] bg-gradient-main animate-gradient-x rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-2000"></div>
        <div className="absolute right-32 top-64 w-[40rem] h-[40rem] bg-gradient-main animate-gradient-x rounded-full mix-blend-multiply filter blur-3xl opacity-70 animate-blob animation-delay-4000"></div>
      </div>
    </div>
  );
};

export const GradientSwirls = () => {
  return (
    <div
      id="bg-wrap"
      className="z-0 w-full h-full absolute top-0 left-0 overflow-hidden"
    >
      <svg
        className="z-0 h-full w-full"
        viewBox="-80 -10 100 100"
        // viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <radialGradient
            id="Gradient1"
            cx="50%"
            cy="50%"
            fx="0.441602%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="34s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#200840"></stop>
            <stop offset="100%" stop-color="rgba(32, 8, 64, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient2"
            cx="50%"
            cy="50%"
            fx="2.68147%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="23.5s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#30CECF"></stop>
            <stop offset="100%" stop-color="rgba(48, 207, 207, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient3"
            cx="50%"
            cy="50%"
            fx="0.836536%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="21.5s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#FAFFC8"></stop>
            <stop offset="100%" stop-color="rgba(250, 255, 199, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient4"
            cx="50%"
            cy="50%"
            fx="4.56417%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="23s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#323D83"></stop>
            <stop offset="100%" stop-color="rgba(49, 60, 129, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient5"
            cx="50%"
            cy="50%"
            fx="2.65405%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="24.5s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#292463"></stop>
            <stop offset="100%" stop-color="rgba(40, 35, 97, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient6"
            cx="50%"
            cy="50%"
            fx="0.981338%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="25.5s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="#230F49"></stop>
            <stop offset="100%" stop-color="rgba(35, 15, 72, 0)"></stop>
          </radialGradient>
        </defs>
        {/* <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient4)">
    <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="17s" repeatCount="indefinite"/>
    </rect>
    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient5)">
    <animate attributeName="x" dur="23s" values="0%;-25%;0%" repeatCount="indefinite" />
    <animate attributeName="y" dur="24s" values="25%;-25%;25%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="18s" repeatCount="indefinite"/>
    </rect>
    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient6)">
    <animate attributeName="x" dur="25s" values="-25%;0%;-25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="26s" values="0%;-25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="19s" repeatCount="indefinite"/>
    </rect> */}
        <rect
          x="13.744%"
          y="1.18473%"
          width="100%"
          height="100%"
          fill="url(#Gradient1)"
          transform="rotate(334.41 50 50)"
        >
          <animate
            attributeName="x"
            dur="20s"
            values="25%;0%;25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="21s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="7s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="-2.17916%"
          y="35.4267%"
          width="100%"
          height="100%"
          fill="url(#Gradient2)"
          transform="rotate(255.072 50 50)"
        >
          <animate
            attributeName="x"
            dur="23s"
            values="-25%;0%;-25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="24s"
            values="0%;50%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="12s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="9.00483%"
          y="14.5733%"
          width="100%"
          height="100%"
          fill="url(#Gradient3)"
          transform="rotate(139.903 50 50)"
        >
          <animate
            attributeName="x"
            dur="25s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="12s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 50 50"
            to="0 50 50"
            dur="9s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
      </svg>
    </div>
  );
};

export const GradientSwirlsOriginal = () => {
  return (
    <div id="bg-wrap" className="z-0 w-full h-full absolute overflow-hidden">
      <svg
        viewBox="-60 -10 100 100"
        // viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <radialGradient
            id="Gradient1"
            cx="50%"
            cy="50%"
            fx="0.441602%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="34s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(255, 0, 255, 1)"></stop>
            <stop offset="100%" stop-color="rgba(255, 0, 255, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient2"
            cx="50%"
            cy="50%"
            fx="2.68147%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="23.5s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(255, 255, 0, 1)"></stop>
            <stop offset="100%" stop-color="rgba(255, 255, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient3"
            cx="50%"
            cy="50%"
            fx="0.836536%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="21.5s"
              values="0%;3%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(0, 255, 255, 1)"></stop>
            <stop offset="100%" stop-color="rgba(0, 255, 255, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient4"
            cx="50%"
            cy="50%"
            fx="4.56417%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="23s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(0, 255, 0, 1)"></stop>
            <stop offset="100%" stop-color="rgba(0, 255, 0, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient5"
            cx="50%"
            cy="50%"
            fx="2.65405%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="24.5s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(0,0,255, 1)"></stop>
            <stop offset="100%" stop-color="rgba(0,0,255, 0)"></stop>
          </radialGradient>
          <radialGradient
            id="Gradient6"
            cx="50%"
            cy="50%"
            fx="0.981338%"
            fy="50%"
            r=".5"
          >
            <animate
              attributeName="fx"
              dur="25.5s"
              values="0%;5%;0%"
              repeatCount="indefinite"
            ></animate>
            <stop offset="0%" stop-color="rgba(255,0,0, 1)"></stop>
            <stop offset="100%" stop-color="rgba(255,0,0, 0)"></stop>
          </radialGradient>
        </defs>
        {/* <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient4)">
    <animate attributeName="x" dur="20s" values="25%;0%;25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="21s" values="0%;25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="17s" repeatCount="indefinite"/>
    </rect>
    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient5)">
    <animate attributeName="x" dur="23s" values="0%;-25%;0%" repeatCount="indefinite" />
    <animate attributeName="y" dur="24s" values="25%;-25%;25%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="18s" repeatCount="indefinite"/>
    </rect>
    <rect x="0" y="0" width="100%" height="100%" fill="url(#Gradient6)">
    <animate attributeName="x" dur="25s" values="-25%;0%;-25%" repeatCount="indefinite" />
    <animate attributeName="y" dur="26s" values="0%;-25%;0%" repeatCount="indefinite" />
    <animateTransform attributeName="transform" type="rotate" from="360 50 50" to="0 50 50" dur="19s" repeatCount="indefinite"/>
    </rect> */}
        <rect
          x="13.744%"
          y="1.18473%"
          width="100%"
          height="100%"
          fill="url(#Gradient1)"
          transform="rotate(334.41 50 50)"
        >
          <animate
            attributeName="x"
            dur="20s"
            values="25%;0%;25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="21s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="7s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="-2.17916%"
          y="35.4267%"
          width="100%"
          height="100%"
          fill="url(#Gradient2)"
          transform="rotate(255.072 50 50)"
        >
          <animate
            attributeName="x"
            dur="23s"
            values="-25%;0%;-25%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="24s"
            values="0%;50%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 50 50"
            to="360 50 50"
            dur="12s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
        <rect
          x="9.00483%"
          y="14.5733%"
          width="100%"
          height="100%"
          fill="url(#Gradient3)"
          transform="rotate(139.903 50 50)"
        >
          <animate
            attributeName="x"
            dur="25s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animate
            attributeName="y"
            dur="12s"
            values="0%;25%;0%"
            repeatCount="indefinite"
          ></animate>
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="360 50 50"
            to="0 50 50"
            dur="9s"
            repeatCount="indefinite"
          ></animateTransform>
        </rect>
      </svg>
    </div>
  );
};
