const LoadingIcon = () => {
  return (
    <svg
      className="text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export const PrimaryButton = ({
  text,
  clickHandler,
  darkBg = false,
  loading = false,
}) => {
  return (
    <button
      className="flex transform active:scale-95 transition duration-200 ease-out"
      onClick={clickHandler}
    >
      <div
        className={`p-[0.2rem] rounded-full bg-gradient-main animate-gradient-x transition duration-500 ease-in-out
        ${
          darkBg
            ? "shadow-xl-primary hover:shadow-lg-primary"
            : "shadow-xl hover:shadow-lg"
        }`}
      >
        <div
          className={`rounded-full bg-black transition duration-500 ease-in-out
          ${darkBg ? "hover:bg-opacity-80" : "hover:bg-opacity-70"}
          ${loading ? "animate-pulse" : "animate-none"}`}
        >
          <span className="py-2 px-10 min-w-[12rem] inline-block text-center rounded-full font-bold text-white">
            {loading && (
              <div className="h-4 w-4 absolute left-8 bottom-[0.95rem] animate-spin">
                <LoadingIcon />
              </div>
            )}
            {text}
          </span>
        </div>
      </div>
    </button>
  );
};

export const SecondaryButtonGradient = ({ text, clickHandler }) => {
  return (
    <button className="flex" onClick={clickHandler}>
      <div className="p-[0.2rem] rounded-full bg-gradient-main animate-gradient-x shadow-xl hover:shadow-lg transition duration-500 ease-in-out">
        <span className="py-2 px-10 min-w-[12rem] inline-block text-center rounded-full font-bold text-black bg-[#FDFDFD]">
          {text}
        </span>
      </div>
    </button>
  );
};

export const SecondaryButtonSVG = ({
  text,
  color,
  clickHandler,
  darkBg = false,
}) => {
  const borderWidth = 0.2;
  const height = 2.6; // rem

  // Do not modify below.
  const _heightRem = `${height}rem`;
  const _borderWidthRem = `${borderWidth}rem`;
  const _roundingRem = `${height / 2}rem`;
  const _artboardHeightRem = `${height + borderWidth * 2}rem`;
  const _rectWidth = `calc(100% - ${borderWidth * 2}rem)`;

  return (
    <button
      style={{ height: _artboardHeightRem }}
      className={`relative transform active:scale-95 transition duration-200 ease-out`}
      // className="relative button-shadow-xl after:button-shadow-lg after:opacity-0 hover:opacity-1 transition duration-500 ease-in-out"
      onClick={clickHandler}
    >
      {/* <div className="min-w-[12rem] button-shadow-xl hover:button-shadow-lg transition duration-500 ease-in-out"> */}
      <div
        className={`min-w-[12rem] transition duration-500 ease-in-out
      ${
        darkBg
          ? "button-shadow-xl-primary hover:button-shadow-lg-primary"
          : "button-shadow-xl hover:button-shadow-lg"
      }`}
      >
        <span
          className="px-10 whitespace-nowrap font-bold"
          style={{ color: color }}
        >
          {text}
          <svg
            className="absolute -top-1/2 left-0"
            // width={_artboardWidthRem}
            width="100%"
            height={_artboardHeightRem}
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              rx={_roundingRem}
              stroke-width={_borderWidthRem}
              height={_heightRem}
              // width={_widthRem}
              width={_rectWidth}
              y={_borderWidthRem}
              x={_borderWidthRem}
              stroke={color}
              fill="rgba(0, 0, 0, 0)"
            />
          </svg>
        </span>
      </div>
    </button>
  );
};

export const SecondaryButtonBlack = ({ text, clickHandler }) => {
  return (
    <button
      className="py-2 px-10 min-w-[12rem] text-center border-[0.2rem] text-black border-black font-bold rounded-full bg-transparent shadow-xl hover:shadow-lg transition duration-500 ease-in-out"
      onClick={clickHandler}
    >
      {text}
    </button>
  );
};

export const SecondaryButtonWhite = ({ text, clickHandler }) => {
  return (
    <button
      className="py-2 px-10 min-w-[12rem] text-center border-[0.2rem] text-white border-white font-bold rounded-full bg-transparent shadow-xl hover:shadow-lg transition duration-500 ease-in-out"
      onClick={clickHandler}
    >
      {text}
    </button>
  );
};
