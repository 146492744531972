import { PrimaryButton, SecondaryButtonWhite } from "./Button";

export const TryAlpha = () => {
  return (
    <div className="px-5 relative bg-black overflow-hidden">
      <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
          <div className="sm:max-w-lg">
            <h1 className="text-4xl font-bold leading-none text-white sm:text-6xl">
              Alpha live on Internet Computer
            </h1>
            <p className="mt-4 text-xl text-white">
              Our alpha is finally ready to be tested, live on the Internet
              Computer. Send and receive your first decentralized messages.
            </p>
            <div className="mt-10 mx-auto sm:mx-0 w-min">
              <a
                href="https://2prn7-tqaaa-aaaai-acjja-cai.ic0.app/"
                target="_blank"
                rel="noreferrer"
              >
                <PrimaryButton text="Try alpha" darkBg={true} />
              </a>
            </div>
          </div>
          <div>
            <div className="mt-10">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                        <img
                          src="https://miro.medium.com/max/4910/1*_WUahnmYxycqQHZng0qRWg.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/4800/1*E8O_P3QAHUZ7ylTBkfZiGQ.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/4800/1*guz0NRDfCNOU5nnfsiEsFw.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/3584/1*Zf4sCBgzdJ9Mqer32sdV4Q.png"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/4800/1*guz0NRDfCNOU5nnfsiEsFw.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/4910/1*p2c5cJFzM_ofb3MwLW3_6A.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src="https://miro.medium.com/max/4910/1*_RTGrb3VUFCqZoWrzOqvRw.jpeg"
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
