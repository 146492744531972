import {
  LightningBoltIcon,
  FingerPrintIcon,
  AtSymbolIcon,
  BanIcon,
  EyeOffIcon,
  ShieldCheckIcon,
  CubeTransparentIcon,
  BadgeCheckIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "No email address needed",
    description:
      "Dbox uses your Internet Identity and nothing else. Websites communicate to you by your public domain-unique Principal ID associated with the user identity context.",
    icon: AtSymbolIcon,
  },
  {
    name: "Forget using passwords",
    description:
      "Since dbox uses Internet Identity for authentication and authorization, you can simply access your inbox with fingerprint or facial recognition - giving you the best user experience possible.",
    icon: FingerPrintIcon,
  },
  {
    name: "Say goodbye to spam",
    description:
      "You will only be able to receive messages from canisters that you have interacted with and approved as senders. Simply put, your inbox will be spam-free.",
    icon: BanIcon,
  },
  {
    name: "Unparalleled privacy",
    description:
      "Websites only need your Principal ID, which is unique to each website. It is impossible to trace your Principal ID to your inbox and thus, your identity.",
    icon: EyeOffIcon,
  },
  {
    name: "Blockchain security",
    description:
      "Dbox operates completely on-chain. Your data is securely stored on the Internet Computer, only accessible by you.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Instantaneous delivery",
    description:
      "Forget waiting hours - or even days - for an email to be delivered. Since dbox is built on the Internet Computer, it operates at web speed.",
    icon: LightningBoltIcon,
  },
  {
    name: "Decentralized",
    description:
      "Don't let your private messages be sold to the highest bidder. With dbox, only you can access your data, enabling you to take control of your personal information.",
    icon: CubeTransparentIcon,
  },
  {
    name: "Trustless",
    description:
      "The code of dbox will be open-source and verifiable on chain for a trustless user experience.",
    icon: BadgeCheckIcon,
  },
  {
    name: "Self governed",
    description:
      "Dbox will be governed by a native governance token, aligning incentives for all participants. Keep an eye out for future airdrops and token sales!",
    icon: RefreshIcon,
  },
  // {
  //   name: "Mobile notifications",
  //   description:
  //     "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
  //   icon: LightningBoltIcon,
  // },
];

export const SellingPoints = () => {
  return (
    <div className="mx-10 py-32">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <h2 className="text-3xl font-bold">Introducing the dApp inbox</h2>
          <p className="mt-4 mx-auto max-w-2xl text-xl text-gray-500">
            With over 25 years since the current email protocol was invented and
            revolutionized the way we communicate, the web - along with our
            expectations - has shifted drastically. It is about time for the
            next generation web communication.
          </p>
        </div>

        <div className="mt-20">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-20 md:gap-y-10">
            {features.map((feature) => (
              <div
                key={feature.name}
                className="p-10 rounded-lg group border border-primary border-opacity-30 hover:border-transparent hover:shadow-2xl-primary bg-transparent hover:bg-white transition duration-300 ease-in-out"
              >
                <dt>
                  <div className="absolute h-[3rem] w-12 rounded-md bg-gradient-main animate-gradient-x">
                    <div className="h-full w-full flex items-center justify-center rounded-md bg-primary group-hover:bg-opacity-0 transition duration-1000 ease-in-out text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                  </div>
                  <h3 className="ml-16 min-h-[3rem] lg:min-h-0 text-lg leading-6 font-bold">
                    {feature.name}
                  </h3>
                </dt>
                <dd className="mt-5 lg:mt-2 lg:ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};
