import React from "react";
import { ReactComponent as Logo } from "../img/dbox_logo.svg";

export const Footer = () => {
  return (
    <div className="py-20 px-5 mx-auto container flex flex-col items-center justify-center">
      <div>
        <Logo className="w-32 mb-10" />
      </div>
      <div className="pt-3 flex flex-col items-center">
        <h1 className="text-2xl font-black text-center">
          The decentralized inbox.
        </h1>
        <div className="md:flex items-center mt-5 md:mt-10 text-base text-color">
          <a
            href="https://2prn7-tqaaa-aaaai-acjja-cai.ic0.app/"
            target="_blank"
            rel="noreferrer"
          >
            Alpha
          </a>
        </div>
        <div className="my-6">
          <ul className="flex gap-6">
            <li className="cursor-pointer">
              <a
                href="https://twitter.com/DBOXFoundation"
                aria-label="Twitter"
                data-title="Twitter"
                target="_blank"
              >
                Twitter
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://discord.gg/y4CEQWqkrr"
                aria-label="Discord"
                data-title="Discord"
                target="_blank"
              >
                Discord
              </a>
            </li>
            <li className="cursor-pointer">
              <a
                href="https://medium.com/@DBOXFoundation"
                aria-label="Medium"
                data-title="Medium"
                target="_blank"
              >
                Medium
              </a>
            </li>
          </ul>
        </div>
        <div className="text-sm text-color">
          <p>© 2022 dbox. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};
