import { ReactComponent as Logo } from "../img/dbox_logo.svg";

export const Nav = () => {
  return (
    <header
      class="z-10 px-6 py-3 w-full flex items-center justify-between bg-gray-100 rounded-b-2xl bg-clip-padding bg-opacity-30 border-b border-gray-200 border-opacity-70"
      style={{ backdropFilter: "blur(10px)" }}
    >
      {/* <header class="z-50 px-5 py-3 w-full absolute flex items-center justify-between"> */}
      <div class="inline-flex items-center">
        <Logo className="w-20" />
      </div>
      <ul class="flex items-center space-x-8">
        <li>
          <a
            href="https://twitter.com/DBOXFoundation"
            aria-label="Twitter"
            data-title="Twitter"
            target="_blank"
            class="flex flex-col justify-center text-xl text-gray-700 transition-colors duration-500 ease-in-out hover:text-secondary"
          >
            <div className="flex pointer-events-none">
              <ion-icon name="logo-twitter"></ion-icon>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://discord.gg/y4CEQWqkrr"
            aria-label="Discord"
            data-title="Discord"
            target="_blank"
            class="flex flex-col justify-center text-xl text-gray-700 transition-colors duration-500 ease-in-out hover:text-secondary"
          >
            <div className="flex pointer-events-none">
              <ion-icon name="logo-discord"></ion-icon>
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://medium.com/@DBOXFoundation"
            aria-label="Medium"
            data-title="Medium"
            target="_blank"
            class="flex flex-col justify-center text-xl text-gray-700 transition-colors duration-500 ease-in-out hover:text-secondary"
          >
            <div className="flex pointer-events-none">
              <ion-icon name="logo-medium"></ion-icon>
            </div>
          </a>
        </li>
      </ul>
    </header>
  );
};
