import dfinityLogoHorisontal from "../img/dfinity-logo-horisontal.svg";
import dfinityLogoVertical from "../img/dfinity-logo-vertical.svg";

export const BackedBy = () => {
  return (
    <div className="h-72 md:h-64 flex justify-center place-items-center bg-dfinity-background">
      <a
        className="mx-5 pt-6 px-12 md:py-4 md:px-16 flex flex-col md:flex-row justify-center place-items-center md:gap-5 bg-white rounded-3xl md:rounded-full"
        href="https://dfinity.org/grants/"
      >
        <span className="text-3xl">Grant from</span>
        <img
          className="h-16 pointer-events-none hidden md:block"
          src={dfinityLogoHorisontal}
          alt="Lorem Ipsum Mail"
        />
        <img
          className="h-28 pointer-events-none md:hidden"
          src={dfinityLogoVertical}
          alt="Lorem Ipsum Mail"
        />
      </a>
    </div>
  );
};
