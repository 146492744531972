import { Content } from "./components/Content";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div class="flex flex-col justify-between bg-gradient-radial-at-r from-[#FDFDFD] to-[#F0F0F3] selection:bg-secondary">
      <Content />
      <Footer />
    </div>
  );
}

export default App;
